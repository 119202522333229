import apiClient from './base'

export default {
    async postLogin(params) {
        return apiClient.post("/login", params)
    },

    async postLogout() {
        return apiClient.post("/logout");
    },

    async postForgotPassword(params) {
        return apiClient.post("/forgot-password", params);
    },

    async postResetPassword(params) {
        return apiClient.post("/reset-password", params);
    },

    async getMe() {
        return apiClient.get("/me");
    },

    async updateMe(userId, params) {
        return apiClient.put(`/users/${userId}`, params);
    },

    async updatePassword(params) {
        return apiClient.put(`/me/password`, params);
    },

    async putAcknowledgeWelcome() {
        return apiClient.put(`/me/acknowledge-welcome-popup`);
    },

    async putAcknowledgeTeamLeadPopup() {
        return apiClient.put(`/me/acknowledge-tl-popup`);
    },

    async validateLink(params) {
        return apiClient.post(`/validate/link`, params);
    }
}
