import Team from "./Team.js";

export default class User {
    nextPopup = {}

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.email = data.email;
        this.is_setup = data.is_setup;
        this.current_team = data.current_team ? new Team(data.current_team) : null;
        this.teams = data.teams ? data.teams.map(team => new Team(team)) : [];
        this.job_role = data.job_role;
        this.timezone = data.timezone;
        this.assessment_due = data.assessment_due;
        this.last_assessment_request_id = data.last_assessment_request_id;
        this.initials = data.initials;
        this.profile_picture = data.profile_picture;
        this.colour = data.colour;
        this.create_challenge = data.create_challenge;
        this.vote_challenge = data.vote_challenge;
        this.daily_question = data.daily_question;
        this.permissions = data.permissions;
        this.should_see_welcome_popup = data.should_see_welcome_popup;
        this.should_see_nps_popup = data.should_see_nps_popup;
        this.should_see_team_lead_popup = data.should_see_team_lead_popup;
        this.ai_enabled = data.ai_enabled;
        this.locale = data.locale;
        this.locale_formatted = data.locale_formatted;
        this.current_organisation = data.current_organisation;
        this.assessment_version = data.assessment_version;
        this.created_at = data.created_at;
    }

    shouldCreateChallenges() {
        return this.create_challenge;
    }

    shouldVoteChallenges() {
        return this.vote_challenge;
    }

    hasCurrentTeam() {
        return this.current_team !== null;
    }

    hasCurrentOrganisation() {
        return this.current_organisation !== null;
    }

    canViewOrganisationAnalytics() {
        return this.current_organisation?.permissions?.viewAnalytics;
    }

    getLang() {
        switch (this.language) {
            case 'en':
                return 'English';
            case 'fr':
                return 'French';
            case 'it':
                return 'Italian';
        }
    }

    firstNameOrName() {
        if (this.first_name) {
            return this.first_name;
        }

        return this.name;
    }
}